import React, { createContext, useState, useEffect, useContext } from 'react';
import { S3Service, ACCESS_LEVELS } from '../../services/S3.service';
import {  UPDATE_PROFILE_PICTURE, UPDATE_USER } from './graphQL/mutations';
import { GET_USER } from './graphQL/queries';
import { useMutation, useLazyQuery } from '@apollo/client';
import { v1 as uuidv1 } from 'uuid';
import { currentImageTime } from './currentImage';

export const userContext = React.createContext(null);
const s3Service = new S3Service();
const DEFAULT_PROFILE_PICTURE = '/static/img/student-profile/default-profile-picture.svg';

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loadingPicture, setLoadingPicture] = useState(true);
  const [updatingPicture, setUpdatingPicture] = useState(false);
  const [profilePictureUrl, setProfilePictureUrl] = useState('');
  const [deletingPicture, setDeletingPicture] = useState(false);
  const [deletingAvatar, setDeletingAvatar] = useState(false);
  const [loadingAvatar, setLoadingAvatar] = useState(true);
  const [profileAvatarUrl, setProfileAvatarUrl] = useState('');
  const [updatingAvatar, setUpdatingAvatar] = useState(false);
  

  const [updateProfilePictureMutation] = useMutation(UPDATE_PROFILE_PICTURE);
  const [updateUserMutation] = useMutation(UPDATE_USER);
  
  const [getUser, { data }] = useLazyQuery(GET_USER);

  useEffect(() => {
    if (user) {
      getUser({
        variables: { id: user.attributes?.sub }
      });
    }
  }, [user]);

  useEffect(() => {
    if (data && !updatingPicture) {
      initializeProfilePicture();
    }
  }, [data, updatingPicture]);

  const initializeProfilePicture = async () => {
    if (data.getUser?.profilePicture) {
      const url = await getProfilePictureUrl(data.getUser?.profilePicture);
      const avatarUrl = await getProfileAvatarUrl(data.getUser?.profileAvatar);
      setProfileAvatarUrl("");
      setProfilePictureUrl("")
      setProfileAvatarUrl(avatarUrl);
      setProfilePictureUrl(url);

    } else {
      setProfilePictureUrl(DEFAULT_PROFILE_PICTURE);
    }
    setLoadingPicture(false);
  };

  const deletePicture = async () => {
    try {
      setDeletingPicture(true);
      const fileName = data.getUser?.profilePicture;
      fileName && (await s3Service.deleteFileByName(fileName, ACCESS_LEVELS.PUBLIC));
      await updateProfilePictureMutation({
        variables: {
          userId: user.attributes.sub,
          profilePicture: ''
        }
      });
      setProfilePictureUrl(DEFAULT_PROFILE_PICTURE);
    } catch (error) {
      console.error(error);
    }
    setDeletingPicture(false);
  };

  const getProfilePictureUrl = async profilePicture => {
    const url = `${s3Service.prefix}${profilePicture}`;
    const correctedUrl = url.replace('undefined', 'public');
    return correctedUrl;
  };

  const getProfileAvatarUrl = async profileAvatar => {
    //const url = await s3Service.getPresignedUrl(profileAvatar, ACCESS_LEVELS.PUBLIC);
    const url = `${s3Service.prefix}${profileAvatar}`;
    const correctedUrl = url.replace('undefined', 'public');
    return correctedUrl;
  };

  const uploadProfilePicture = async blob => {
   // const name = `profile-picture__${uuidv1()}`;
   
    const name = `profile-picture__${uuidv1()}__${currentImageTime()}__`;
    try {
      setUpdatingPicture(true);
      await Promise.all([
        s3Service.uploadImage({ name, blob }, ACCESS_LEVELS.PUBLIC),
        updateProfilePictureMutation({
          variables: {
            userId: user.attributes.sub,
            profilePicture: name
          }
        })
      ]);

      const url = await getProfilePictureUrl(name);
      setProfilePictureUrl("");
      setProfilePictureUrl(url);
      /* localStorage.setItem('avatar', url); */
      setUpdatingPicture(false);

      // optener metadatos para verificar fecha de subida 
      return url;
    } catch (error) {
      if (error.message === 'Choose an image file') {
        alert('Select an image file');
      } else {
        throw error;
      }
      console.error(error);
      setUpdatingPicture(false);
    }
  };

  const uploadProfileAvatar = async blob => {
   // const name = `profile-avatar__${uuidv1()}`;
    const name = `profile-avatar__${uuidv1()}__${currentImageTime()}__`;
    try {
      setUpdatingAvatar(true); 

      await Promise.all([
        s3Service.uploadImage({ name, blob }, ACCESS_LEVELS.PUBLIC),
        updateUserMutation({
          variables: {
            input: {
              id: user.attributes.sub,
              profileAvatar: name
            }
          }
        })
      ]);

      const url = await getProfileAvatarUrl(name);
      setProfileAvatarUrl("");
      setProfileAvatarUrl(url);
      /*  localStorage.setItem('avatar', url); */
      setUpdatingAvatar(false);
      return url;
    } catch (error) {
      if (error.message === 'Choose an image file') {
        alert('Select an image file');
      } else {
        throw error;
      }
      console.error(error);
      setUpdatingAvatar(false);
    }
  };

  

  const contextData = {
    user,
    setUser,
    loadingPicture,
    profilePictureUrl,
    setProfilePictureUrl,
    uploadProfilePicture,
    updatingPicture,
    deletePicture,
    deletingPicture,
    loadingAvatar,
    profileAvatarUrl,
    setProfileAvatarUrl,
    uploadProfileAvatar,
    updatingAvatar,
    deletingAvatar,
    initializeProfilePicture
  };

  return <userContext.Provider value={contextData}>{children}</userContext.Provider>;
};

export const useUser = () => {
  const context = useContext(userContext);
  if (context === undefined) {
    throw new Error('useUser can only be used inside UserProvider');
  }
  return context;
};

export default UserProvider;
